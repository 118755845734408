.tracking-search-form .form-control {
  border-color: black;
  height: 60px;
  border-radius: 100px;
  padding-left: 20px;
  font-size: 17px;
}

.tracking-search-form .btn {
  background-color: #be1f2d;
  border: none;
  border-radius: 2px;
  padding: 7px 25px;
}

.btn_light {
  background-color: #be1f2d !important;

}

a {
  color: #be1f2d !important
}

.tracking-search-form .btn:hover {
  filter: brightness(90%);
}

.tracking-search-form .form-group {
  display: flex;
  align-items: center;
  gap: 20px;
}

.badge {
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .tracking-search-form .form-group {
    flex-direction: column;
  }
}

.form-check-input:checked {
  background-color: #be1f2d !important;
  border: 1px solid #be1f2d !important
}