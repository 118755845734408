.tracker-item {
  margin: 5px 0px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.tracker-item-icon {
  width: 50px;
  height: 50px;
  color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  border: 1px solid gray;
}
