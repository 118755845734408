@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css");

@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/react-toastify/dist/ReactToastify.min.css";

@import "./styles/components/tracker-item.css";

@import "./styles/home.css";

* {
  font-family: sans-serif;
}

input,
button {
  outline: none !important;
  box-shadow: none !important;
}

.btn_light {
  background-color: #319ae5;
  border: none;
  border-radius: 2px;
  padding: 7px 25px;
  padding: 10px 30px;
  border-radius: 50px;
}

.btn_light:hover {
  background-color: #319ae5;
  filter: brightness(90%);
}

.btn_light:focus {
  background-color: #319ae5;
  filter: brightness(90%);
}

.form-ctrl {
  border-color: black;
  height: 60px;
  border-radius: 100px;
  padding-left: 20px;
  font-size: 17px;
  text-align: center;
}

.form-ctrl::placeholder {
  color: black;
}

.form-ctrl:focus {
  border-color: black;
}